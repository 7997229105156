<template>
  <!-- 支付失败 -->
  <div id="app">
    <div class="cart-con confirm-con pay-con">
      <div class="cart-ban">
        <img src="@/assets/img/confirm-ban.png" />
      </div>
      <div class="cson-container">
        <div class="current">
          <ul>
            <li>
              <img src="@/assets/img/confirm-cur1.png" />
              <p class="cur">确认客户订单</p>
              <span></span>
            </li>
            <li>
              <img src="@/assets/img/confirm-cur22.png" />
              <p class="cur">订单支付</p>
              <span></span>
            </li>
            <li>
              <img src="@/assets/img/confirm-cur33.png" />
              <p class="cur">支付结果</p>
            </li>
          </ul>
        </div>
        <div class="pay-result">
          <div class="result">
            <img src="@/assets/img/paydefault-icon.png" />
            <h2>支付失败，请<span>重新支付</span></h2>
          </div>
          <div class="code-con">
            <img v-if="qr" :src="qr.imageUrl" class="code" />
            <p>付款遇到问题？请扫码联系客服</p>
          </div>
          <div class="result-btn">
            <button class="repay" @click="repay">重新支付</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCourseBanner } from "../../service";

export default {
  data() {
    return {
      qr: null,
    };
  },
  watch: {},
  created() {
    // 拿二维码图片
    getCourseBanner(6)
      .then((res) => {
        console.log(res);
        if (!res.result.code) {
          console.log(res);
          this.qr = res.result[0];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {},
  methods: {
    repay() {
      this.$router.push({ path: "/orderpay" });
    },
  },
};
</script>

<style>
</style>
